<template>
  <b-overlay :show="showLoading">
    <b-row v-if="post !== null">
        <b-col cols="12">
          <b-card>

            <h4>ساخت پست</h4>

            <b-row>
              <b-col
                  cols="12"
                  md="6"
                  class="my-2"
              >
                <b-form-group
                    label="نوع مدیا"
                    label-for="type"
                >
                  <v-select
                      v-model="post.mediaFile.priority"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                  />
                </b-form-group>
              </b-col>

              <b-media v-if="post.mediaFile.priority === 2" class="my-2 col-12">
                <template >
                  <b-avatar
                      class="cursor-pointer"
                      ref="previewEl"
                      :src="base64ImageSrc"
                      size="300px"
                      rounded
                      @click.native="$refs.refInputEl1.click()"
                  >
                    <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100" />
                  </b-avatar>
                </template>
                <div class=" flex-wrap mt-1">
                  <b-button
                      variant="primary"
                      class="d-none"
                  >
                    <input
                        ref="refInputEl1"
                        type="file"
                        class="d-none"
                        accept=".jpg,.jpeg,.png"
                        @input="makeBase64Pic($event,'base64ImageSrc')"
                    >
                    <span class="d-none d-sm-inline">+</span>
                    <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-button
                      variant="danger"
                      class=""
                      @click="deletePic()"
                  >
                    <span class="d-none d-sm-inline">حذف</span>
                    <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>

<!--              <b-media v-if="post.mediaFile.priority === 3" class="my-2 col-12">-->
<!--                <template >-->
<!--                  <b-avatar-->
<!--                      v-if="base64VideoSrc === null"-->
<!--                      class="cursor-pointer"-->
<!--                      ref="previewEl2"-->
<!--                      size="300px"-->
<!--                      rounded-->
<!--                      @click.native="$refs.refInputEl2.click()"-->
<!--                  >-->
<!--                    <feather-icon  icon="PlusSquareIcon" size="100" />-->
<!--                  </b-avatar>-->
<!--                  <video-->
<!--                      v-else-->
<!--                      :src="base64VideoSrc"-->
<!--                      autoplay-->
<!--                      style="max-height: 30rem;max-width: 100%"-->
<!--                  ></video>-->
<!--                </template>-->
<!--                <div class="flex-wrap mt-1">-->
<!--                  <b-button-->
<!--                      class="d-none"-->
<!--                      variant="primary"-->
<!--                  >-->
<!--                    <input-->
<!--                        ref="refInputEl2"-->
<!--                        type="file"-->
<!--                        class="d-none"-->
<!--                        accept=".mp4"-->
<!--                        @input="makeBase64Pic($event,'base64VideoSrc')"-->
<!--                    >-->
<!--                    <span class="d-none d-sm-inline">+</span>-->
<!--                    <feather-icon-->
<!--                        icon="EditIcon"-->
<!--                        class="d-inline d-sm-none"-->
<!--                    />-->
<!--                  </b-button>-->
<!--                  <b-button-->
<!--                      variant="danger"-->
<!--                      class=""-->
<!--                      @click="deletePic()"-->
<!--                  >-->
<!--                    <span class="d-none d-sm-inline">حذف</span>-->
<!--                    <feather-icon-->
<!--                        icon="TrashIcon"-->
<!--                        class="d-inline d-sm-none"-->
<!--                    />-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </b-media>-->

<!--              <b-alert-->
<!--                  :show="post.mediaFile.priority === 3"-->
<!--                  variant="danger"-->
<!--                  class="col-8 mx-1"-->
<!--              >-->
<!--                <div class="alert-body ">-->
<!--                  <feather-icon-->
<!--                      class="mr-25"-->
<!--                      icon="InfoIcon"-->
<!--                  />-->
<!--                  <span class="ml-25">حجم فیلم مورد نظر باید کمتر از 25 مگابایت باشد.</span>-->
<!--                </div>-->
<!--              </b-alert>-->

            </b-row>

            <!-- Address -->
            <b-form-group
                label="کپشن"
                label-for="description"
            >
              <b-form-textarea
                  id="description"
                  v-model="post.content"
                  placeholder="کپشن ..."
              />
            </b-form-group>

            <!-- Action Buttons -->
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="updatePost"
            >
              ثبت
            </b-button>

          </b-card>
        </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from "vue-select";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {PostGetRequest} from "@/libs/Api/Post";
import {PostUpdateRequest} from "@/libs/Api/Post";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
export default {
  name: "PostEdit",
  title: 'آپدیت پست - پنل ادمین مکس ',
  data(){
    return{
      post:null,
      typeOptions:[
        {label : 'عکس' , value : 2},
        {label : 'فیلم' , value : 3}
      ],
      base64ImageSrc:null,
      base64VideoSrc:null,
      postId:this.$route.params.id,
      showLoading:false,
    }
  },
  async created(){
    await this.getPost();
  },
  methods:{
    async getPost(){
      let _this = this;

      _this.showLoading=true;
      let postGetRequest = new PostGetRequest(_this);
      postGetRequest.setId(_this.postId);
      await postGetRequest.fetch(function (content){
        _this.showLoading=false;
        _this.post = content;

        if (_this.post.mediaFile.priority === 2){
          _this.base64ImageSrc = 'https://api.maxterms.com/media/gallery/post/'+_this.post.mediaFile.base64;
        }else {
          _this.base64VideoSrc = 'https://api.maxterms.com/media/gallery/post/'+_this.post.mediaFile.base64;
        }
        _this.post.mediaFile.base64 = '';
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e,index){
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.post.mediaFile.base64  = await _this.readAsDataURL(file);
    },
    deletePic() {
      this.base64ImageSrc = null;
      this.base64VideoSrc = null;
    },
    async updatePost(){
      let _this = this;

      _this.showLoading=true;
      let postUpdateRequest = new PostUpdateRequest(_this);
      postUpdateRequest.setParams(_this.post);
      await postUpdateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name:'apps-post-list'})
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
    test(){
      console.log(this.createData.content)
    },
  },
  components:{
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
}
</script>

<style scoped>

</style>